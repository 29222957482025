


@font-face {
  font-family: "Bold";
  src: local("DesySansOfficeCn-Bold"),
    url("./fonts/DesySansOfficeCn-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Heavy";
  src: local("DesySansOfficeCn-Heavy"),
    url("./fonts/DesySansOfficeCn-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("DesySansOfficeCn-Medium"),
    url("./fonts/DesySansOfficeCn-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";
  src: local("DesySansOfficeCn-Regular"),
    url("./fonts/DesySansOfficeCn-Regular.ttf") format("truetype");
}

:root {
  --background-color: #fffaf0; /* Floral White */
  --primary-text-color: #4b3832; /* Dark Brown */
  --secondary-color: #deb88780; /* BurlyWood with opacity */
  --sec-background: #fffaf0; /* Floral White */
}

[data-theme="dark"] {
  --background-color: #1e1e1e; /* Dark Gray */
  --primary-text-color: #e0e0e0; /* Light Gray */
  --secondary-color: #3e3e3e; /* Medium Dark Gray */
  --sec-background: #2e2e2e; /* Slightly Lighter Dark Gray */
}

.cat{
  position: absolute;
  bottom: 0px;
  left: 0;

}
.cat2{
  position: absolute;
  top: -35px;
  left: 0;
  height: 100px;
}
.catdiv{
  position: absolute;
  font-family: "Bold";
  color: var(--primary-text-color);
  font-size: 40px;
  bottom: 60px;
  left: 40vh;
}
body {
  height: 100vh;
  font-family: "Bold";
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

.App {
  background-color: var(--background-color);
}

.container {
  width: 100%;
  max-width: 1700px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.parent {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.header {
  grid-area: 2 / 2 / 1 / 5;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

.switch-parent {
  grid-area: 1 / 1 / 2 / 2;
}

h1 {
  color: var(--primary-text-color);
}

.sensor-data-container {
  grid-area: 2 / 1 / 3 / 6;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 20px;
}

.historical-data-chart {
  grid-area: 3 / 1 / 7 / 6;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--sec-background);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.sensor-box {
  flex: 1 1 calc(12.5% - 40px);
  max-width: calc(12.5% - 40px);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: var(--secondary-color);
}

.sensor-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.selected {
  border: 3px solid #b92d41;
}

.sensor-icon {
  font-size: 24px;
  color: var(--primary-text-color);
  margin-bottom: 10px;
}

.sensor-header {
  font-size: 16px;
  color: var(--primary-text-color);
}

.sensor-info {
  font-size: 20px;
  color: var(--primary-text-color);
}

.tracker-win {
  grid-area: 4 / 6 / 7 / 9;
  height: 100%;
  width: 100%;
  background-color: var(--sec-background);
  border-radius: 10px;
  font-size: 14px;
  color: var(--primary-text-color);
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chart-container {
  margin-bottom: 20px;
}

.chart {
  width: 100%;
}

.controls {
  display: flex;
  align-items: center;
  margin: 5px;
}

.interval-input {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  font-size: 14px;
  background-color: var(--sec-background);
  border-radius: 12px;
  border-color: var(--sec-background);
  color: var(--primary-text-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.start-button,
.stop-button {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  border-radius: 4px;
  transition: background-color 0.4s ease;
}

.start-button:disabled,
.stop-button:disabled {
  background-color: var(--background-color);
  cursor: not-allowed;
}

.start-button:hover,
.stop-button:hover {
  background-color: var(--secondary-color);
}

.info-box {
  grid-area: 1 / 6 / 4 / 9;
  height: 100%;
  width: 100%;
  background-color: var(--sec-background);
  border-radius: 10px;
  font-size: 14px;
  color: var(--primary-text-color);
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.info-header {
  grid-area: 1 / 1 / 2 / 5;
  text-align: center;
}

.sensor-infobox {
  grid-area: 2 / 1 / 5 / 3;
  height: 100%;
  width: 100%;
  background-color: var(--sec-background);
  border-radius: 5px;
  font-size: 14px;
  color: var(--primary-text-color);
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.sensor-infobox p {
  margin-left: 10px;
}

.historical-cpu-chart {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sensor-data-container .cpu-temp {
  color: var(--primary-text-color);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-text-color);
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--background-color);
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-text-color);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

body {
  width: 100%;
  height: 100%;
}





