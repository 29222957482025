/* Style for the gear icon */
#gear {
  cursor: pointer;
}


.configheader button{
  grid-area: 1 / 1 / 2 / 2;

}
.configheader h2{
  grid-area: 1 / 2 / 2 / 4;
}
/* Style for the config modal */
.config-modal {
  position: fixed;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto; /* Adjusted for content */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  max-height: 80vh; /* Responsive height */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  width: 80vw; /* Responsive width */
}


.config-modal-content {
  grid-area: 2 / 1 / 6 / 6;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.config-close-button {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.config-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.config-container-ip {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.config-ip, .config-port {
  border: none;
  padding: 10px;
  font-size: 16px;
  flex: 1;
}

.config-ip {
  border-right: 1px solid #ccc;
}

.config-at-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: #f5f5f5;
}

.config-save-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.config-save-button:hover {
  background-color: #0056b3;
}
